import InjectBaseComponents from '@/components/base'
import { getAnalytics, setUserId } from 'firebase/analytics'
import { createApp as createVueApp } from 'vue'
import App from './App.vue'
import Forbidden from './Forbidden.vue'
import './registerServiceWorker'
import router from './router'
import { i18n } from './i18n'
import { DateTime, Settings } from 'luxon'
import Toast, { POSITION } from 'vue-toastification'
import { weekDays } from '@/utils/CONF'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import 'vue-toastification/dist/index.css'


import mitt from 'mitt'
const emitter = mitt()


Settings.defaultLocale = navigator.language
export function createApp({ firebaseUser, user, roles }) {
  const analytics = getAnalytics()
  setUserId(analytics, user.uid)

  const app = createVueApp(App)
    .provide('analytics', analytics)
    .provide('user', firebaseUser)
    .provide('userInfos', user)
    .provide('roles', roles)
    .use(router)
    .use(i18n)
    .use(FloatingVue)
    .use(Toast, { position: POSITION.BOTTOM_LEFT })

  app.config.globalProperties.$filters = {
    formatNumber(value, digit = 2, unit = '', addPlus = false) {
      if (!Number.isFinite(value)) return ' '
      const result =  value.toFixed(digit)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                          .concat(unit)
      if(addPlus && value >= 0) return '+'.concat(result)
      return result
    },
    formatDate(value, format = 'dd LLLL y') {
      const date = DateTime.fromISO(value)
      if (!date?.invalid) return date.toFormat(format)
      return DateTime.fromFormat(value, 'yyyy-LL-dd HH:mm').toFormat(format)

    },
    formatKeyDay(keyDay) {
      return weekDays[keyDay - 1]
    },
    userIsReam() {
      return roles?.opticam?.includes('ream') ?? false
    },
    userIsAdmin() {
      return roles?.opticam?.includes('admin') ?? false
    },
    isSameSite(userInfos, siteId) {
      return userInfos?.site && +userInfos?.site.site === +siteId
    }
  }

  InjectBaseComponents(app)
  app.mount('#app')
  app.provide('emitter', emitter)
}

export function createForbiddenApp({ firebaseUser, userInfos, roles }) {
  const app = createVueApp(Forbidden)
    .provide('user', firebaseUser)
    .provide('userInfos', userInfos)
    .provide('roles', roles)

  InjectBaseComponents(app)
  app.mount('#app')
}
