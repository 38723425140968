<template>
  <template v-if="!loading">
    <app-nav />
    <div class="container">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
      <fab-buttons />
    </div>
    <modal-refresh v-if="hasNewContent" @close="hasNewContent = false" @refresh="refreshApp" />
  </template>
  <loader v-else />
</template>

<script>
  import { DateTime } from 'luxon'
  import { inject, onBeforeMount, ref } from 'vue'
  import AppNav from '@/components/AppNav'
  import useReferentialStore from '@/store/referential.store'
  import { useRoute } from 'vue-router'
  import useContextStore from '@/store/context.store'
  import FabButtons from './components/layout/fabButtons/FabButtons'

  export default {
    name: 'Opticam',
    components: { FabButtons, AppNav },
    setup() {
      const hasNewContent = ref(false)
      const refreshing = ref(false)
      const newRegistration = ref(null)

      const showRefreshUI = e => {
        newRegistration.value = e.detail
        hasNewContent.value = true
      }

      const refreshApp = () => {
        hasNewContent.value = false
        newRegistration.value?.waiting.postMessage({ type: 'SKIP_WAITING' })
      }

      document.addEventListener('swUpdated', showRefreshUI, { once: true })
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (refreshing.value) return
          // eslint-disable-next-line no-console
          console.info('[Service worker] refreshing app')
          refreshing.value = true
          window.location.reload()
        })
      }

      const loading = ref(true)

      const contextStore = useContextStore()
      const { setSelectedNom, setSelectedGeo, setSelectedPeriod, getSelectedFavorites } = contextStore

      const referentialStore = useReferentialStore()
      const { fetchSites, fetchNomenclature, getNomItemByTypeAndLevelAndId } = referentialStore

      const route = useRoute()

      const userInfos = inject('userInfos')

      onBeforeMount(async() => {
        await Promise.all([fetchSites(), fetchNomenclature(), getSelectedFavorites()])

        const { startDate, geoLevel, geoId, nomLevel, nomId } = route.query

        if (startDate) {
          const date = DateTime.fromISO(startDate)
          setSelectedPeriod({ weekNumber: date.weekNumber, year: date.year })
        }

        if (geoLevel && geoId) setSelectedGeo([getNomItemByTypeAndLevelAndId({ type: 'geo', level: geoLevel, id: geoId })])
        else if (userInfos?.site !== null && Object.keys(userInfos?.site)?.length && userInfos?.site?.site && userInfos?.site?.site !== '001') setSelectedGeo([getNomItemByTypeAndLevelAndId({ type: 'geo', level: 'site', id: userInfos.site.site })])

        if (nomLevel && nomId) setSelectedNom([getNomItemByTypeAndLevelAndId({ type: 'nom', level: nomLevel, id: parseInt(nomId) })])

        loading.value = false
      })

      return {
        hasNewContent,
        refreshApp,
        loading
      }
    }
  }
</script>

<style lang="scss">
  #app {
    display: flex;
    flex-direction: column;

    .container {
      padding: 0 4rem;
      margin-top: 2rem;
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
</style>
