<template>
  <div class="background" />
  <div class="delete-dr-confirmation-popup">
    <div class="header">
      <div class="title">{{ $t('deleteDrConfirmationPopup.title') }}</div>
      <v-svg h="0.8rem" name="cross" class="close-cross" @click="$emit('close')" />
    </div>
    <div class="container">
      <div> {{ $t('deleteDrConfirmationPopup.confirmDeleteMessage') }} </div>
      <div class="container-infos">
        <div class="infos-dr">
          <div class="infos-row">
            <div class="infos-label">{{ $t('deleteDrConfirmationPopup.site') }}</div>
            <div class="infos-value">{{ data?.site }} - {{ data?.siteLabel }}</div>
          </div>
          <div class="infos-row">
            <div class="infos-label">{{ $t('deleteDrConfirmationPopup.openingDay') }}</div>
            <div class="infos-value">{{ $filters.formatDate(data?.openingDate.day, 'dd LLL y') }}</div>
          </div>
          <div class="infos-row">
            <div class="infos-label">{{ $t('deleteDrConfirmationPopup.refDay') }}</div>
            <div class="infos-value">{{ $filters.formatKeyDay(data?.openingDate.keyDay) }}</div>
          </div>
          <div class="infos-row">
            <div class="infos-label">{{ $t('deleteDrConfirmationPopup.calculateDay') }}</div>
            <div class="infos-value">{{ $filters.formatDate(data?.openingDate.calculatedDay, 'dd LLL y') }}</div>
          </div>
        </div>
      </div>

      <div class="confirm">{{ $t('deleteDrConfirmationPopup.confirmDeleteAsk') }}</div>
      <div class="buttons">
        <div class="keep-button" @click="$emit('close')">
          <span>{{ $t('deleteDrConfirmationPopup.keepButton') }}</span>
        </div>
        <div :class="[{'disable-button': loading }, 'delete-button']" @click="deleteDr">
          <div>
            <v-svg v-if="!loading" h="1.2rem" name="trash" />
            <v-svg v-else h="1.2rem" name="spinner" />
            <span>{{ $t('deleteDrConfirmationPopup.deleteButton') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { extendWithAuthorization, http } from '@/http'
import { inject, ref, watch } from 'vue'
import { useToast } from 'vue-toastification'
import { getSitesInGeo } from '@/utils/indicators.utils'

export default {
  name: 'DeleteDrConfirmationPopup',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['close', 'deleteDr'],
  setup(props, {emit}) {

    const loading = ref(false)

    const toast = useToast()
    const userInfos = inject('userInfos')
    const emitter = inject("emitter")

    const deleteDr = async() => {

      loading.value = true
      const json = {
        sites: [props.data.site],
        openingDate: props.data?.openingDate.day,
        doubleRestockingChoice: false,
        doubleRestockingDate: props.data?.openingDate.calculatedDay,
        dayReference: props.data?.openingDate.keyDay,
        userCode: userInfos?.uid
      }

      const extendedHttp = await extendWithAuthorization(http)
      await extendedHttp
        .post(`warehouse/double-restocking`, { json })
        .json()
        .then(() => {
          emitter.emit('reloadDRData')
          emit('close')
          emit('deleteDr')
          toast.success("Date d'ouverture magasin supprimée avec succès")
        })
        .catch(err => {
          toast.error("Une erreur est survenue lors de la suppression de votre date d'ouverture")
          console.error(err)
        })
      loading.value = false
    }

    return { deleteDr, loading  }
  }
}
</script>

<style scoped lang="scss">
.background {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 30;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
}

.delete-dr-confirmation-popup {
  z-index: 31;
  position: fixed;
  left: 50%;
  top: 50%;
  height: 60vh;
  width: 50vw;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 1);
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  border-radius: 16px;

  .loader { height: 100%; }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;

    .title {
      @extend %primary-font--bold;
      font-size: $font-size-big;
    }

    .close-cross {
      cursor: pointer;
      margin-top: -0.8rem;
    }
  }

  .container {
    overflow-y: auto;
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;

      .infos-dr {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 300px;
        height: 200px;
        left: 171px;
        top: 84px;
        background: #F6EEE9;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        align-items: center;

        .infos-row {
          align-items: center;
          display: flex;
          flex-direction: column;
          width: 70%;

          &:not(:last-child) { border-bottom: 2px solid white; }

          .infos-label {
            color: #A6A6A6;
            font-size: 12px;
          }
          .infos-value {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }


    .confirm {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;

      .delete-button, .keep-button {
        diplay: flex;
        border: 1px solid #BC8A5F;
        border-radius: 5px;
        cursor: pointer;
        padding: 8px 12px;
        margin-left: 2rem;
      }

      .delete-button {
        color: #FFF;
        background-color: #BC8A5F;

        &.disable-button {
          cursor: default;
          border: 1px solid #c4c2c2;
          color: white;
          background-color: #c4c2c2;
        }

        div {
          display: flex;
          flex-direction: row;
          align-items: center;

          span {
            padding-left : .6rem;
          }

          v-svg {
            color: white
          }
        }
      }

      .keep-button {
        color: #BC8A5F;
      }

    }
  }
}
</style>
