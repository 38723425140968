<template>
  <div class="background" />
  <div class="shop-opening-popup">
    <v-svg h="0.6rem" name="cross" class="close-cross" @click="$emit('close')" />
    <div class="title">{{ $t('shopOpeningPopup.title') }}</div>
    <div class="row">
      <div class="opening-date row-item">
        <div class="label">{{ $t('shopOpeningPopup.openingDate') }}</div>
        <date-picker-day :selected-period="luxonOpeningDate" @select="data.openingDate = $event" />
      </div>

      <div class="double-restocking-date row-item">
        <div class="label">{{ $t('shopOpeningPopup.doubleRestockingDate') }}</div>
        <date-picker-day :selected-period="luxonDoubleRestockingDate" @select="data.doubleRestockingDate = $event" />
      </div>
    </div>
    <div class="row">
      <div class="day-reference row-item">
        <div class="label">{{ $t('shopOpeningPopup.dayReference') }}</div>
        <div class="select">
          <div v-for="(day, i) in days"
               :key="i"
               :class="[{ active: data.dayReference === i + 1 }, 'item']"
               @click="data.dayReference = i + 1">
            {{ day }}
          </div>
        </div>
      </div>
    </div>
    <geo-selection :formats="selectedFormats" :contracts="selectedContracts" :light-mode="true" @input="data.geo = $event" />
    <div class="footer">
      <div class="close button" @click="$emit('close')">
        <div class="btn-label">{{ $t('shopOpeningPopup.close') }}</div>
        <v-svg h="0.6rem" name="cross" />
      </div>
      <div class="cancel button" @click="reset()">
        <div class="btn-label">{{ $t('shopOpeningPopup.cancel') }}</div>
        <v-svg h="0.9rem" name="undo" />
      </div>
      <div :class="[loading ? 'disable' : 'validate', 'button']" @click="validate">
        <div class="btn-label">{{ $t('shopOpeningPopup.validate') }}</div>
        <v-svg v-if="!loading" h="0.8rem" name="validate" />
        <v-svg v-else h="1.2rem" name="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
  import { DateTime } from 'luxon'
  import { computed, inject, ref } from 'vue'
  import { weekDays } from '@/utils/CONF'
  import { extendWithAuthorization, http } from '@/http'
  import { getSitesInGeo } from '@/utils/indicators.utils'
  import { useToast } from 'vue-toastification'

  export default {
    name: 'ShopOpeningPopup',
    emits: ['close', 'countUpdated'],
    setup(props, {emit}) {
      const toast = useToast()

      const initData = {
        openingDate: DateTime.now(),
        doubleRestockingChoice: true,
        doubleRestockingDate: DateTime.now(),
        dayReference: 1,
        geo: undefined
      }

      const data = ref({ ...initData })

      const luxonOpeningDate = computed(() => DateTime.fromISO(data.value.openingDate))
      const luxonDoubleRestockingDate = computed(() => DateTime.fromISO(data.value.doubleRestockingDate))

      const days = ref(weekDays)

      const selectedFormats = ref([])
      const selectedContracts = ref(['INT', 'FRA'])

      const userInfos = inject('userInfos')

      let loading = ref(false)
      const validate = async() => {
        if(!loading.value) {
          loading.value = true

          let { openingDate, doubleRestockingChoice, doubleRestockingDate, dayReference } = { ...data.value }

          openingDate = openingDate.toISODate()
          doubleRestockingDate = doubleRestockingDate.toISODate()

          if (openingDate <= doubleRestockingDate) {
            loading.value = false
            return toast.error("La date d'ouverture ne peut pas être antérieure ou égale à la date de calcul")
          }

          if (openingDate <= DateTime.now().toISODate()) {
            loading.value = false
            return toast.error("La date d'ouverture ne peut pas être dans le passé")
          }

          if (!data.value?.geo?.length) {
            loading.value = false
            return toast.error("Veuillez selectionner un ou plusieurs site(s)")
          }

          const sites = getSitesInGeo(data.value.geo)

          const json = {
            sites,
            openingDate,
            doubleRestockingChoice,
            doubleRestockingDate,
            dayReference,
            userCode: userInfos?.uid
          }

          const extendedHttp = await extendWithAuthorization(http)
          await extendedHttp
            .post(`warehouse/double-restocking`, { json })
            .json()
            .then(() => {
              emit('countUpdated')
              toast.success("Date d'ouverture magasin ajoutée avec succès")
            })
            .catch(err => {
              toast.error("Une erreur est survenue lors de l'ajout de votre date d'ouverture")
              console.error(err)
            })
          loading.value = false
        }
      }

      const reset = () => data.value = { ...initData }

      return { data, luxonOpeningDate, luxonDoubleRestockingDate, days, selectedFormats, selectedContracts, validate, reset, loading }
    }
  }
</script>

<style scoped lang="scss">
  .background {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 30;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
  }

  .shop-opening-popup {
    z-index: 31;
    position: fixed;
    left: 50%;
    top: 50%;
    height: 90vh;
    width: 80vw;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 1);
    padding: 1rem 2.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 16px;

    .close-cross {
      position: absolute;
      top: 10px;
      right: 15px;
      cursor: pointer;
    }

    .title {
      @extend %primary-font--bold;
      font-size: $font-size-title;
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
    }

    .row {
      display: flex;
      margin-bottom: 1rem;

      .row-item {
        margin-right: 2rem;
        @extend %secondary-font--bold;
        font-size: $font-size-small;

        .label { margin-bottom: 0.5rem; }
      }

      .opening-date {
        border-right : 1px solid #cfcfcf;
      }
    }

    .select {
      display: flex;
      border-radius: 7px;
      width: fit-content;
      user-select: none;

      .item {
        display: flex;
        align-items: center;
        padding: 6px;
        cursor: pointer;
        border: 1px solid #808080;
        border-right: none;

        &.active {
          background: #404040;
          color: white;
          border: none;

          .v-svg { color: white; }
        }

        &:first-child { border-radius: 7px 0 0 7px; }
        &:last-child {
          border-radius: 0 7px 7px 0;
          border-right: 1px solid #808080;
        }

        &:not(:last-child) { border-right: 1px solid rgba(128, 128, 128, 1); }

        .v-svg { margin-right: 0.5rem; }
      }
    }

    .geo-selection {
      position: relative;
      top: 0;
      left: 0;
      height: 60%;
      width: 100%;
    }

    .footer {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 1rem;
      right: 2.5rem;
      @extend %secondary-font--bold;
      font-size: $font-size-small;

      .button {
        margin-left: 2rem;
        display: flex;
        align-items: center;
        padding: 8px 12px;
        cursor: pointer;

        &.disable{
          cursor: default;
          background: #c4c2c2;
          color: white;
          border-radius: 5px;
        }

        &.cancel, &.close {
          border: 1px solid #808080;
          color: #808080;
          border-radius: 5px;
        }

        &.validate {
          background: var(--app-color);
          color: white;
          border-radius: 5px;
        }

        .svg-cross { margin-top: -6px; }
        .v-svg { margin-left: 0.5rem; }
      }
    }
  }
</style>
