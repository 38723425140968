<template>
  <div class="smart-notifications">
    <button class="notifications-button" :class="{ ringing: unreadNotifs }" @click="opened = true">
      <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 24 24" width="2em">
        <path
          fill="currentColor"
          d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-1.29 1.29c-.63.63-.19 1.71.7 1.71h13.17c.89 0 1.34-1.08.71-1.71L18 16z" />
      </svg>
      <div v-if="unreadNotifs" class="chip">{{ unreadNotifs }}</div>
    </button>
    <transition name="fade">
      <div v-if="opened" class="notifications-backdrop" @click.self="close">
        <transition name="pop">
          <div class="notifications-popin">
            <button class="close" @click="close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
            <div class="title">Notifications</div>
            <div class="subtitle">{{ subtitle }}</div>
            <div class="notifications">
              <div v-for="notif in notifications" :key="notif.id" class="notification">
                <div class="notification-header">
                  <div class="title"> {{ notif.title }} </div>
                  <div class="timestamp"> {{ `il y a ${timastamp(notif.start)}` }} </div>
                </div>
                <div class="notification-content">{{ notif.text }} </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { Duration } from 'luxon'

export default {
  name: 'Notification',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      opened: false,
      notifications: [],
      readedNotifs: []
    }
  },
  computed: {
    storageKey() {
      return `${process.env.VUE_APP_NAME}:readedNotifs`
    },
    subtitle() {
      const nbNotif = this.notifications.length
      const plural = nbNotif > 1 ? 's' : ''
      return `Tu as ${nbNotif} nouvelle${plural} notification${plural}`
    },
    unreadNotifs() {
      return this.notifications.filter(n => !this.readedNotifs.includes(n.id)).length
    }
  },
  async mounted() {
    try {
      this.readedNotifs = JSON.parse(localStorage.getItem(this.storageKey)) || []
    } catch (e) {
      this.readedNotifs = []
    }
    await this.getNotifs()
    this.opened = this.unreadNotifs
  },
  methods: {
    close() {
      this.readedNotifs = this.notifications.map(n => n.id)
      localStorage.setItem(this.storageKey, JSON.stringify(this.readedNotifs))
      this.opened = false
    },
    async getNotifs() {
      if (!process.env.VUE_APP_BACKEND_SMART) {
        console.error(`[SmartNotifs] Missing VUE_APP_BACKEND_SMART env variable`)
        return
      }
      if (!process.env.VUE_APP_NAME) {
        console.error(`[SmartNotifs] Missing VUE_APP_NAME env variable`)
        return
      }
      const token = await this.user.getIdToken()
      this.notifications = await fetch(`${process.env.VUE_APP_BACKEND_SMART}/notif`, {
        headers: { Authorization: `Bearer ${token}`, application: process.env.VUE_APP_NAME }
      })
        .then(res => res.json())
        .catch(console.error)

      if (!this.notifications.length) {
        localStorage.removeItem(this.storageKey)
      }
      return Promise.resolve(this.notifications)
    },
    timastamp(t) {
      const durationMillis = Date.now() - t
      const duration = Duration.fromMillis(durationMillis)
        .shiftTo('months', 'days', 'hours', 'minutes', 'seconds')
        .toObject()

      if (duration.months) return `${duration.months}mois`
      else if (duration.days) return `${duration.days}j`
      else if (duration.hours) return `${duration.hours}h`
      else return `${duration.minutes}min`
    }
  }
}
</script>

<style lang="scss" scoped>
.smart-notifications {
  color: white;
  .notifications-button {
    font-size: inherit;
    background: none;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
    height: 100%;
    align-items: center;

    @media screen and (max-width: 1200px) {
      height: 2em;
      width: 2em;
      padding: 0;
      border-radius: 50%;
    }
    svg {
      transform-origin: 50% 0em;
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(320deg) brightness(107%) contrast(107%);
    }
    &.ringing svg,
    &:hover svg {
      animation: bell 2s ease-in-out infinite;
    }
    .chip {
      color: white;
      position: absolute;
      font-size: 0.5625em;
      font-weight: bold;
      background: #DA4453;
      right: 0;
      top: 0;
      width: 0.75em;
      height: 0.75em;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.notifications-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  backdrop-filter: blur(24px);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1200px) {
    align-items: flex-end;
    width: 100%;
  }
  .notifications-popin {
    color: black;
    background-color: white;
    border-radius: 2em;
    padding: 2em;
    margin: 1em;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 9.375em;
    max-height: stretch;
    position: relative;
    @media screen and (max-width: 1200px) {
      width: stretch;
    }
    @media screen and (min-width: 1201px) {
      align-items: flex-start;
      min-width: 25em;
      max-width: 80vw;
      max-height: 80vh;
    }

    .close {
      position: absolute;
      right: 2em;
      top: 2em;
      background: none;
      border: none;
      outline: none;
    }
    > .title {
      font-size: 1.7em;
      font-weight: 700;
    }
    > .subtitle {
      color: #DA4453;
      font-weight: 700;
    }
    .notifications {
      overflow: auto;
      width: 100%;
      .notification {
        margin-top: 1em;
        &-header {
          display: flex;
          justify-content: space-between;
          font-size: 1rem;
          .title {
            font-weight: 600;
          }
          .timestamp {
            font-size: 0.75em;
            color: #a3a3a3;
            white-space: nowrap;
          }
        }
        &-content {
          margin-top: 0.5em;
          font-size: 1.2em;
          text-align: justify;
          white-space: pre-wrap;
        }
      }
    }
  }
}

@keyframes bell {
  0% {
    transform: rotateZ(0);
  }
  5% {
    transform: rotateZ(20deg);
  }
  10% {
    transform: rotateZ(-18deg);
  }
  15% {
    transform: rotateZ(16deg);
  }
  20% {
    transform: rotateZ(-14deg);
  }
  25% {
    transform: rotateZ(12deg);
  }
  30% {
    transform: rotateZ(-10deg);
  }
  35% {
    transform: rotateZ(8deg);
  }
  40% {
    transform: rotateZ(-6deg);
  }
  45% {
    transform: rotateZ(4deg);
  }
  50% {
    transform: rotateZ(0);
  }
}
.fade-enter-active {
  transition: opacity 0.2s;
  .notifications-popin {
    animation: pop 0.3s;
  }
}

.fade-leave-active {
  transition: opacity 0.2s;
  transition-delay: 0.1s;
  .notifications-popin {
    animation: pop 0.2s reverse;
  }
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  .notifications-popin {
    transform: scale(0.5);
  }
}

@keyframes pop {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
